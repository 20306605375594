import React from "react";

const Education: React.FC = () => {
  return (
    <section id="education">
      <h2 className="text-4xl font-bold mb-2">Education</h2>
      <div className="qualifications">
        {/* Add qualifications here */}
        <div className="qualification mb-2">
          <h3 className="text-1xl font-bold">
            MComp Computer Science | September 2024
          </h3>
          <p>University of Lincoln</p>
          <p>Grade: Distinction</p>
        </div>
        <div className="qualification mb-2">
          <h3 className="text-1xl font-bold">A Levels | March 2020</h3>
          <p>Queen Elizabeth's High School, Gainsborough</p>
          <p>Grades: Mathematics (C), Physics (C), Chemistry (D)</p>
        </div>
      </div>
    </section>
  );
};

export default Education;

