import React from 'react';
import { LinkedinOriginal } from 'devicons-react';
import { BsMailbox } from 'react-icons/bs';

const Contact: React.FC = () => {
  return (
    <section id="contact" className="p-4 section">
      <h2 className="text-4xl font-bold mb-2">Contact Me</h2>
      <ul className="flex justify-center space-x-6 mt-4">
        <li>
          <a href="https://www.linkedin.com/in/stewart-charles-fisher-ii/" target="_blank" rel="noopener noreferrer">
            <LinkedinOriginal size="3rem" className="jiggle-grow" />
          </a>
        </li>
        <li>
          <a href="mailto:findme@khthonian.dev" target="_blank" rel="noopener noreferrer">
            <BsMailbox size="3rem" className="jiggle-grow" />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Contact;

