import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [navVisible, setNavVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setNavVisible(false);
    }
  };

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  const hideNav = () => {
    setNavVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={`${scrolled ? 'scrolled' : ''}`}>
      <div className="nav-container">
        <button className="nav-toggle" onClick={toggleNav}>
          <FontAwesomeIcon icon={navVisible ? faTimes : faBars} />
        </button>
      </div>
      <ul className={`nav-items ${navVisible ? 'nav-visible' : 'nav-hidden'}`}>
        <li><a href="#about" onClick={hideNav}>About</a></li>
        <li><a href="#experience" onClick={hideNav}>Experience</a></li>
        <li><a href="#education" onClick={hideNav}>Education</a></li>
        <li><a href="#projects" onClick={hideNav}>Projects</a></li>
        <li><a href="#contact" onClick={hideNav}>Contact</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
