import React from "react";
import { GithubOriginal } from "devicons-react";

const Projects: React.FC = () => {
  return (
    <section id="projects" className="p-4">
      <h2 className="text-4xl font-bold mb-2">Projects</h2>
      <div className="flex flex-col items-center">
        <p className="mb-4 text-lg">Check out my projects on GitHub:</p>
        <a
          href="https://github.com/Khthonian"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GithubOriginal size="3rem" className="jiggle-grow" />
        </a>
      </div>
    </section>
  );
};

export default Projects;
