import React from "react";

const Header: React.FC = () => {
  return (
    <header>
      <h1 className="text-6xl font-bold mb-2">Stewart Charles Fisher II</h1>
      <p className="text-3xl">Software Engineer</p>
    </header>
  );
};

export default Header;
